.app {
  margin: 0;
}

.app .app_container {
  display: flex;
  justify-content: center;
}

.app .app_container .logo_container {
  width: 500px;
  max-width: calc(100% - 20px);
  padding: 0 10px;
  display: flex;
  justify-content: center;
}

.app .app_container .logo_container img {
  width: 50%;
  margin-top: 4%;
  cursor: pointer;
}

.app .app_container .header_container {
  display: flex;
  width: 50%;
  justify-content: space-evenly;
  margin-top: 3%;
}

.app .app_container .header_container span {
  font-size: 1.3em;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  cursor: pointer;
}

.app .app_container .header_container span:hover {
  font-weight: 700;
  color: #fe5819;
}

@media screen and (max-width: 500px) {
  .app_container {
    flex-direction: column;
    align-items: center;
  }
  .logo_container {
    margin-top: 0.8em;
  }
  .app .app_container .header_container {
    gap: 3em;
    margin-top: 1em;
    width: 75%;
  }
  .app .app_container .header_container span {
    font-size: 0.8em;
    text-align: center;
    width: 100%;
  }
}
