@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

.quote_container {
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

@media screen and (max-width: 500px) {
  .quote_vehicle p {
    width: 20em;
  }
  .input_location {
    display: flex;
    flex-direction: column;
  }
  
}
