@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

.main_text {
  font-family: "Lato", sans-serif;
}

.text_top {
}

.main_text span {
  font-size: 3em;
}

.support_text {
  font-family: "Lato", sans-serif;
}
.support_text span {
  font-size: 1.3em;
}

span {
  font-family: "Lato", sans-serif;
}

p {
  font-family: "Lato", sans-serif;
}

.together {
  justify-content: space-evenly;
}

@media screen and (max-width: 500px) {
  .main_text span {
    font-size: 1em;
  }
  .support_text span {
    font-size: 0.7em;
  }
  .together {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .together_text {
    margin-top: 1em;
    text-align: center;
  }
}
