@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

.quote_text p {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  width: 15em;
  font-size: 2.7em;
}

.quote_text span {
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

.quote_form {
}

@media screen and (max-width: 500px) {
  .quote_text p {
    width: 10em;
    font-size: 1.7em;
  }
}
