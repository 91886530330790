@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

.detail_container {
  margin-top: 10em;
  width: 100%;
}

.detail_header {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-size: 3em;
  display: flex;
  justify-content: center;
}

.details_contact {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 10em;
  padding-bottom: 3em;
}

.email_details {
  font-family: "Lato", sans-serif;
}

.location_details {
  font-family: "Lato", sans-serif;
}

@media screen and (max-width: 500px) {
  .details_contact {
    flex-direction: column;
    row-gap: 1em;
    justify-content: center;
    align-items: center;
  }
}
