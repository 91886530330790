@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

.form_cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form_cont .form_head {
  font-family: "Lato", sans-serif;
}

.form_cont .form_head .head {
  font-size: 2em;
  font-weight: 600;
}

.form_cont .form_head .support {
  font-size: 1.2em;
}

.form_cont .form_deets {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.form_cont .form_deets input {
  width: 28em;
  height: 2em;
  border-radius: 0.4em;
  border: solid black;
  padding-left: 1em;
}
.form_cont .form_deets textarea {
  height: 9em;
  border-radius: 0.4em;
  padding-left: 0.7em;
  padding-top: 0.5em;
  font-family: "Lato";
  border: solid black;
}
.form_cont .form_submit button {
  margin-top: 1em;
  width: 28em;
  height: 3em;
  background-color: #fe5819;
  border: none;
  border-radius: 1em;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .form_cont .form_head .head {
    font-size: 1.5em;
  }
  .form_cont .form_head .support {
    font-size: 0.8em;
  }
  .form_cont .form_deets input {
    width: 20em;
  }
  .form_cont .form_submit button {
    width: 18em;
  }
}
