@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

.form_map {
  width: 100%;
  display: flex;
  margin-top: 2%;
}

.form_map .form_contact {
  width: 50%;
  height: 40em;
}

.form_map .map_contact {
  width: 50%;
  height: 30em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form_map .map_contact a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form_map .map_contact img {
  width: 75%;
  border: 4px solid #fe5819;
}

@media screen and (max-width: 500px) {
  .form_map {
    flex-direction: column;
  }
  .form_map .form_contact {
    width: 100%;
    height: 30em;
  }
  .form_map .map_contact {
    width: 100%;
    height: 15em;
  }
}
