@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

/* .home_container {
  width: 100%;
  height: 35em;
  display: flex;
  justify-content: center;
  padding-top: 2em;
}

.home_container .home_text {
  width: 50%;
  display: flex;
  flex-direction: column;

  align-items: center;
}

.home_container .home_text .maintext {
  width: 12em;
  font-family: "Lato";
  font-size: 3em;
}

.home_container .home_text .sidetext {

  font-family: "Lato";
  margin-top: -2em;
  margin-left: -7.9em;
  font-size: 1.2em;
}

.home_container .home_text .home_button {
  display: flex;
  gap: 1.5em;
}

.home_container .home_text .home_button button {
  margin-top: 0.5em;
  width: 10em;
  height: 3.5em;
  background-color: #fe5819;
  border: none;
  cursor: pointer;
} */

.home_text p {
  font-family: "Lato", sans-serif;
  font-weight: 900;
}

.button {
  font-weight: 900;
  font-family: "Lato", sans-serif;
}

.home_container {
  font-family: "Lato", sans-serif;
}

@media screen and (max-width: 500px) {
  .stats_home {
    flex-direction: column;
    row-gap: 2em;
  }
}
